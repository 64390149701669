import * as React from "react";
import api from "../util/api";

import { Button, TextField, Box, Alert, CircularProgress } from "@mui/material";

import Layoutas from "../components/Layout";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Background from "/images/411440x810.jpeg";
import EmailIcon from "@mui/icons-material/Email";
import useTimer from "../components/useTimer";
import TurnStile from "../components/TurnStile";

import "./index.css";

function validateRequire(text) {
  if (text) {
    return null;
  } else {
    return `ต้องกรอกช่องนี้`;
  }
}
function validateEmail(text) {
  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(text)) {
    return `ต้องเป็นประเภทอีเมล`;
  } else {
    return null;
  }
}

export default function EditProfile(props) {
  const [validate_field, setValidateField] = React.useState({
    email: [],
  });
  const [onload, setOnLoad] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [sendemail, setSendEmail] = React.useState(false);
  const [token, setToken] = React.useState(null);
  const [timer, setTimer] = useTimer(null);

  const handleSubmit = () => {
    let input = {
      email: email,
    };
    let check_validate = false;
    let forgetpass_validate = onValidate(input);
    for (let key in forgetpass_validate) {
      check_validate = forgetpass_validate[key].filter((item) => item !== null).length > 0;
      if (check_validate) break;
    }
    if (!check_validate) {
      setOnLoad(true);
      onResetPassSendEmail(input);
    }
  };

  const onResetPassSendEmail = async (data) => {
    console.log("token", token);
    try {
      await api.resetpass({ email: data.email, token: token });
      console.log("data.email", data.email);
      setOnLoad(false);
      setSendEmail(true);
      setTimer(new Date().getTime() + 1000 * 60);
      Swal.fire("", "ระบบได้ส่ง Link สำหรับเปลี่ยนรหัสผ่านใหม่ไปยัง Email : " + data.email + " นี้แล้ว", "success");
    } catch (err) {
      console.log("err", err);
      setOnLoad(false);
      if(err === "Token ไม่ถูกต้อง"){
        Swal.fire("", "Token ไม่ถูกต้อง กรุณาลองใหม่", "error");
      } else {
        Swal.fire("", "ไม่พบ Email นี้ในระบบ", "error");
      }
    }
  };

  const onValidate = (data) => {
    validate_field.email = [];
    validate_field.email.push(validateRequire(data.email), validateEmail(data.email));
    setValidateField({ ...validate_field });
    return validate_field;
  };

  return (
    <Layoutas>
      <div className="h-[80vh] bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${Background})` }}>
        <div className="pt-[250px] p-5 flex justify-center">
          <div className="w-full xl:w-8/12 rounded backdrop-brightness-50 bg-white overflow-hidden shadow-lg">
            <Box component="form">
              <div className="px-6 py-4">
                <div className="font-bold text-lg xl:text-4xl mb-6">รีเซ็ตรหัสผ่านสำหรับเข้าใช้งานระบบ</div>
                <div style={{ borderBottom: "1px solid black", width: "100%" }}></div>

                <div className="pt-4">
                  <TextField
                    required
                    fullWidth
                    onChange={(e) => setEmail(e.target.value)}
                    id="Email"
                    margin="normal"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    error={validate_field.email.filter((item) => item !== null).length > 0}
                    helperText={
                      validate_field.email.filter((item) => item !== null).length > 0 ? validate_field.email.filter((item) => item !== null)[0] : ""
                    }
                  />
                </div>
                {sendemail && <Alert severity="success">{`ระบบได้ส่ง Link สำหรับเปลี่ยนรหัสผ่านใหม่ไปยัง Email : ${email} นี้แล้ว`}</Alert>}
                <div className="flex justify-center items-center mb-4 mt-8">
                  <div className="w-fit justify-center items-center">
                    <TurnStile token={token} setToken={setToken} />
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center mb-8 mt-4 gap-2">
                  {timer > 0 && (
                    <div className="flex justify-center items-center gap-4">
                      <h2 className="my-auto text-lg">ส่งอีเมลล์อีกครั้ง : </h2>
                      <p className="my-auto text-lg">{`00:${timer < 10 ? "0" : ""}${timer}`}</p>
                    </div>
                  )}
                  <Button
                    className="w-fit justify-center items-center"
                    disabled={onload || timer > 0}
                    onClick={handleSubmit}
                    variant="contained"
                    startIcon={onload ? <CircularProgress size={16} color="inherit" /> : <EmailIcon />}
                  >
                    {onload ? "กำลังส่ง Email..." : "Reset Password"}
                  </Button>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </Layoutas>
  );
}
